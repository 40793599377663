import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { formatWithTimeZone } from '@/common/utils';
import { getFilesRedoImported } from '@/common/localStorage';

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'name', label: 'Tên file', sortable: true },
    { key: 'partner', label: 'Tên đối tác', sortable: true },
    { key: 'kind', label: 'Loại', sortable: true },
    { key: 'campaign', label: 'Chiến dịch', sortable: true },
    { key: 'created', label: 'Ngày tạo', sortable: true },
    { key: 'total', label: 'Tổng', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
    {
      key: 'success',
      label: 'Thành công',
      sortable: true,
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'failed',
      label: 'Thất bại',
      sortable: true,
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'process',
      label: 'Tiến trình',
      sortable: true,
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    { key: 'actions', thClass: 'text-left', tdClass: 'text-left' },
  ];
  const perPage = ref(10);
  const totalContract = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const typeFilter = ref(null);
  const statusFilter = ref(null);
  const startDate = ref(null);
  const endDate = ref(null);
  // const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  // const roleFilter = ref(null);
  // const planFilter = ref(null);
  // const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContract.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  const allowRedoImported = (list, id) => {
    if (list && list.length > 0) {
      const theFileIndex = list.findIndex((item) => item.id === id);
      if (theFileIndex !== -1) {
        return false;
      }
    }
    return true;
  };

  const preProcessAData = (object) => ({
    ...object,
    process: Math.ceil(((object.success + object.failed) / object.total) * 100),
    created: formatWithTimeZone(object.created),
    exportFileLoading: false,
    exportFileFullLoading: false,
    redoUploadFileLoading: false,
    redoUploadFileDisable: !allowRedoImported(getFilesRedoImported(), object.id),
  });

  const preProcessData = (data) => data.map((item) => preProcessAData(item));

  watch([currentPage, perPage, statusFilter, typeFilter, startDate, endDate], () => {
    refetchData();
  });

  const fetchContracts = (ctx, callback) => {
    store
      .dispatch('app-customer2/fetchImports', {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        type: typeFilter.value,
        startDate: startDate.value,
        endDate: endDate.value,
      })
      .then((response) => {
        const { data, meta } = response.data;
        const dataFormatted = preProcessData(data);

        callback(dataFormatted);
        totalContract.value = meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Xảy ra lỗi',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Tải danh sách đối tác thất bại',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = (value) => {
    if (value === 'PROCESSING') return 'warning';
    if (value === 'LATE' || value === 'BAD') return 'error';
    if (value === 'PAID') return 'success';
    if (value === 'COLLECTION') return 'secondary';
    return 'primary';
  };

  const statusOptions = [
    { label: 'Mới', value: 0 },
    { label: 'Đang xử lý', value: 1 },
    { label: 'Thu nợ', value: 3 },
    { label: 'Trễ', value: 4 },
    { label: 'Nợ xấu', value: 5 },
    { label: 'Từ chối', value: 6 },
  ];

  const typeOptions = [
    { label: 'Khoản ứng', value: 0 },
    { label: 'Lead', value: 2 },
  ];
  return {
    fetchContracts,
    tableColumns,
    perPage,
    currentPage,
    sortBy,
    isSortDirDesc,
    totalContract,
    dataMeta,
    perPageOptions,
    refUserListTable,
    statusOptions,
    typeOptions,
    statusFilter,
    typeFilter,
    resolveUserStatusVariant,
    refetchData,
    allowRedoImported,
  };
}
